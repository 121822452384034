import React, { ChangeEventHandler, FormEventHandler, MouseEventHandler } from 'react'
import cn from 'classnames'
import cookie from 'cookie'
import { connect, ConnectedProps } from 'react-redux'
import Parser from 'html-react-parser'

import lang from 'lang'
import Head from 'components/head'
import { wrapper } from 'common/store'
import Modal from 'common/components/modal'
import AuthFooter from 'components/auth/footer'
import { AUTHENTICATE_USER } from 'services/actions'
import AuthFeature from 'components/auth/feature-container'
import Form, { FormWithProps } from 'common/components/form'
import ExternalHtml from 'common/services/html-content/external-html'
import {
  changeLoginFormField,
  LoginFields,
  loginRequest,
  validateField,
} from 'common/services/login/actions'
import { AppState } from 'common/store/reducer'

type LocalState = { shownPassword: boolean }

const connector = connect(
  (state: AppState) => ({
    email: state.login.formData.email,
    password: state.login.formData.password,
    rememberMe: state.login.formData.rememberMe,
    loading: state.login.loading,
    errors: state.login.errors,
  }),
  {
    loginRequest,
    validateField,
    changeFormField: changeLoginFormField,
  },
)
type Props = ConnectedProps<typeof connector>

class Login extends Form<LoginFields, FormWithProps<LoginFields, Props>, LocalState> {
  public static getInitialProps = wrapper.getInitialPageProps((store) => async ({ req, res }) => {
    if (req && req.headers.cookie) {
      const cookies = cookie.parse(req.headers.cookie)
      const jwt = cookies['ruler-jwt']
      store.dispatch({ type: AUTHENTICATE_USER, payload: { jwt, res } })
    }
  })

  static validations = {
    email: 'nonEmpty email',
    password: 'nonEmpty lengthMin8',
  }

  state: LocalState = {
    shownPassword: false,
  }

  toggleShownPasswordHandler(): MouseEventHandler<HTMLButtonElement> {
    return () => {
      this.setState((prevState) => ({
        shownPassword: !prevState.shownPassword,
      }))
    }
  }

  rememberMeHandler(): ChangeEventHandler<HTMLInputElement> {
    return (e) => {
      this.props.changeFormField('rememberMe', e.target.checked)
    }
  }

  loginHandler(): FormEventHandler<HTMLFormElement> {
    return (e) => {
      e.preventDefault()
      this.props.loginRequest({
        email: { value: this.props.email.trim(), validations: Login.validations.email },
        password: { value: this.props.password, validations: Login.validations.password },
        rememberMe: { value: this.props.rememberMe },
      })
    }
  }

  render() {
    return (
      <div>
        <Head title="Login" />
        <Modal />
        <div className="main-auth-container">
          <div className="grid-x grid-padding-x align-center">
            <div className="small-11 medium-10 large-4 cell">
              <div className="auth-content">
                <header className="auth-header">
                  <div className="logo">
                    <img
                      src="https://ruleronlineimgs.s3.amazonaws.com/logos/ruler-logo.svg"
                      alt="Ruler logo"
                    />
                  </div>
                  <h1 className="app-auth-title">{lang.auth.login.title}</h1>
                </header>
                <main className="auth-main">
                  <span className={cn('form-error', { 'is-visible': this.props.errors.base })}>
                    {/* Use Parser because some I18n error messages have <a/> in them. */}
                    {Parser(this.props.errors.base || '')}
                  </span>
                  <form
                    id="app-login-form"
                    action=""
                    data-abide
                    noValidate
                    onSubmit={this.loginHandler()}>
                    <label htmlFor="e-mail">{lang.auth.emailLabel}</label>
                    <input
                      type="text"
                      id="e-mail"
                      value={this.props.email}
                      onChange={this.createFieldHandler('email')}
                      onBlur={this.createFieldValidationHandler('email', Login.validations.email)}
                      required
                      className={cn({ 'is-invalid-input': this.props.errors.email })}
                      pattern="email"
                    />
                    <span className={cn('form-error', { 'is-visible': this.props.errors.email })}>
                      {this.props.errors.email}
                    </span>
                    <div className="password-wrapper">
                      <label htmlFor="password">{lang.auth.password}</label>
                      <input
                        type={this.state.shownPassword ? 'text' : 'password'}
                        id="password"
                        value={this.props.password}
                        className={cn({ 'is-invalid-input': this.props.errors.password })}
                        onChange={this.createFieldHandler('password')}
                        onBlur={this.createFieldValidationHandler(
                          'password',
                          Login.validations.password,
                        )}
                        autoComplete="off"
                        required
                        pattern="alpha_numeric"
                      />
                      <span
                        className={cn('form-error', {
                          'is-visible': this.props.errors.password,
                        })}>
                        {this.props.errors.password}
                      </span>
                      <button
                        id="show-password"
                        type="button"
                        className={cn('show-pass', { active: this.state.shownPassword })}
                        aria-hidden="true"
                        onClick={this.toggleShownPasswordHandler()}
                        tabIndex={-1}
                        title={lang.auth.shownPassword}>
                        <i className="icon-eye" />
                      </button>
                      <label htmlFor="remember-check">
                        <input
                          type="checkbox"
                          id="remember-check"
                          onChange={this.rememberMeHandler()}
                        />
                        {lang.rememberMe}
                      </label>
                    </div>
                    <p>
                      <a href="/forgot-password">{lang.auth.forgotPassword}?</a>
                    </p>
                    <button
                      type="submit"
                      id="loginButton"
                      className="button expanded"
                      disabled={this.props.loading}>
                      {lang.auth.logIn}
                    </button>
                    <ExternalHtml slug="login/login-screen-text" />
                  </form>
                </main>
                <AuthFooter />
              </div>
            </div>
          </div>
        </div>
        <AuthFeature />
      </div>
    )
  }
}

export default connector(Login)
