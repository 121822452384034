import React from 'react'
import PropTypes from 'prop-types'
import getConfig from 'next/config'

import lang from 'lang'

const { publicRuntimeConfig } = getConfig()

class IframeModal extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
  }

  renderIFrame() {
    return (
      <iframe
        title={this.props.title}
        src={this.props.src}
        width="100%"
        height="540"
        frameBorder="0"
        className="gfiframe"
      />
    )
  }

  renderIFramePlaceholder() {
    return (
      <div className="iframe-placeholder">
        {lang.iframePlaceholder}
        {this.props.src}
      </div>
    )
  }

  render() {
    return (
      <div className="modal-wrapper">
        <div className="modal-container">
          {publicRuntimeConfig.SERVER_ENV === 'production'
            ? this.renderIFrame()
            : this.renderIFramePlaceholder()}
        </div>
      </div>
    )
  }
}

export default IframeModal
