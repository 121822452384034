import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import cn from 'classnames'
import ReactModal from 'react-modal'
import _ from 'lodash'

import { changeModal } from 'services/actions'
import { AppState } from 'common/store/reducer'

const style = {
  overlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1005,
    backgroundColor: 'rgba(0,0,0,.65)',
    overflowY: 'auto',
    display: 'block',
  },
  content: {
    zIndex: 1006,
    backfaceVisibility: 'hidden',
    padding: '1rem',
    border: '1px solid #cacaca',
    borderRadius: 0,
    backgroundColor: '#fefefe',
    position: 'relative',
    top: '100px',
    marginRight: 'auto',
    margiLeft: 'auto',
    overflowY: 'auto',
    display: 'block',
  },
}

const mapState = (state: AppState) => ({
  isOpen: state.root.modal.isOpen,
  hideCloseButton: state.root.modal.hideCloseButton,
  content: state.root.modal.content,
  className: state.root.modal.className,
  aria: state.root.modal.aria,
  shouldCloseOnOverlayClick: state.root.modal.shouldCloseOnOverlayClick,
  onClose: state.root.modal.onClose,
  style: state.root.modal.style,
})
const connector = connect(mapState, { changeModal })
type Props = ConnectedProps<typeof connector>

class Modal extends React.Component<Props> {
  get style() {
    let defaultStyles = style
    if (_.includes(this.props.className, 'full')) {
      defaultStyles = {
        overlay: style.overlay,
        content: {
          ...style.content,
          top: '0',
        },
      }
    } else if (_.includes(this.props.className, 'after-login-popup')) {
      defaultStyles = {
        overlay: style.overlay,
        content: {
          ...style.content,
          padding: '2rem',
          backgroundColor: '#024985',
        },
      }
    }
    return _.defaultsDeep(this.props.style, defaultStyles)
  }

  closeModal() {
    this.props.changeModal({ isOpen: false })

    if (!_.isUndefined(this.props.onClose)) {
      this.props.onClose()
    }
  }

  handleOverlayClick() {
    return () => {
      if (this.props.shouldCloseOnOverlayClick) {
        this.closeModal()
      }
    }
  }

  render() {
    let closeButton: React.ReactElement | null = (
      <button className="close-button" type="button" onClick={() => this.closeModal()}>
        <span aria-hidden="true">&times;</span>
      </button>
    )
    if (this.props.hideCloseButton) {
      closeButton = null
    }

    return (
      <ReactModal
        isOpen={this.props.isOpen}
        overlayClassName="reveal-overlay"
        className={cn('reveal', this.props.className)}
        onRequestClose={this.handleOverlayClick()}
        style={this.style}
        bodyOpenClassName="is-reveal-open"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
        aria={this.props.aria}>
        {closeButton}
        {this.props.content}
      </ReactModal>
    )
  }
}

export default connector(Modal)
