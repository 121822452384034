import React from 'react'

import ExternalHtml from 'common/services/html-content/external-html'

export default function FeatureContainer() {
  return (
    <div className="feature-container">
      <div className="feature-container-content">
        <h2>
          <ExternalHtml slug="login/left-side-text" />
        </h2>
      </div>
      <div
        className="feature-container-cover"
        style={{
          backgroundImage: 'url(https://s3.amazonaws.com/ruleronlineimgs/ui/ruler-login-cover.jpg)',
        }}
      />
    </div>
  )
}
