import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import lang from 'lang'
import { changeModal } from 'services/actions'
import IframeModal from 'common/components/modals/iframe'

@connect(null, (dispatch) => ({
  changeModal: (params) => dispatch(changeModal(params)),
}))
export default class Footer extends React.Component {
  static propTypes = {
    changeModal: PropTypes.func.isRequired,
  }

  handleContactButton() {
    return () => {
      this.props.changeModal({
        isOpen: true,
        aria: {
          labelledby: 'contact',
        },
        className: 'contact',
        content: <IframeModal src="//help.ruler.online/gfembed/?f=1" title={lang.footer.contact} />,
      })
    }
  }

  render() {
    const footerText = `© ${new Date().getFullYear()} Yale University. ${
      lang.footer.allRightsReserved
    }`
    return (
      <footer className="auth-footer">
        <p className="source-org copyright">{footerText}</p>
        <nav className="footer-nav">
          <ul className="menu simple">
            <li>
              <a href="/terms" rel="noopener noreferrer" target="_blank">
                {lang.tos}
              </a>
            </li>
            <li>
              <a href="/privacy" rel="noopener noreferrer" target="_blank">
                {lang.privacy}
              </a>
            </li>
            <li>
              <a
                role="button"
                onClick={this.handleContactButton()}
                onKeyDown={this.handleContactButton()}
                tabIndex="0">
                {lang.footer.contact}
              </a>
            </li>
          </ul>
        </nav>
      </footer>
    )
  }
}
